import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VueMeta from "vue-meta";
import { auth, onAuthStateChanged  } from "../fb";



Vue.use(VueRouter);
Vue.use(VueMeta);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/House-Washing",
    name: "House-Washing",
    component: () => import("../views/houseWashing.vue")
  },
  {
    path: "/Terms",
    name: "Terms",
    component: () => import("../views/Terms.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue")
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/database",
    name: "database",
    component: () => import("../views/database.vue"),
    meta: { requiresAuth: true }
    
    }];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next("/login");
    } else {
      next();
    }
  });
});

export default router;
