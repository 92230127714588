<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="font-weight-bold"
        v-model="title"
        color="#00aeef"
        dark
        right
        v-on="on"
        >{{ title }}</v-btn
      >
    </template>

    <v-card>
      <v-icon
        size="30"
        right
        class="ma-2 float-right"
        color="#C0C0C0"
        @click="dialog = false"
        >{{ svgPath }}</v-icon
      >
      <v-card-title>
        <v-img
          absolute
          width="140"
          contain
          height="40"
          src="@/assets/goclean-logo.svg"
        />
      </v-card-title>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="6" md="4">
                <v-text-field
                  label="First*"
                  v-model="first"
                  :rules="nameRules"
                  required
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="4">
                <v-text-field
                  label="Last*"
                  v-model="last"
                  :rules="nameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  type="email"
                  v-model="email"
                  required
                ></v-text-field>
              </v-col>
              <v-snackbar
                v-model="snackbar"
                :timeout="2000"
                multi-line
                centered
                absolute
                height="150"
                color="success"
              >
                <span class="title text-center">
                  Thankyou for contacting us, We will be in touch shortly!
                </span>
                <v-progress-circular
                  color="white"
                  :size="40"
                  :width="8"
                  indeterminate
                ></v-progress-circular>
              </v-snackbar>
              <v-col cols="6">
                <v-text-field
                  label="Address*"
                  type="Address"
                  v-model="address"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Phone*"
                  type="tel"
                  v-model="phone"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field type="text" v-model="details" label="*Details">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn
          class="ml-5 mb-3"
          to="/Terms"
          x-small
          color="grey"
          text
          v-on:click="dialog = false"
          >Terms of service</v-btn
        >

        <v-btn
          right
          absolute
          large
          contain
          color="#00aeef"
          class="white--text font-weight-bold ma-0 mr-5 mb-3"
          :disabled="!valid"
          @click="validate"
          >Submit</v-btn
        >
      </v-card-actions>
      <v-spacer class="pa-2"></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>

import { db } from "../fb"; 
import { collection, addDoc } from "firebase/firestore"; 
import { mdiCloseBoxOutline } from "@mdi/js";
export default {
  name: "dialog1",
  props: { title: String, myProp: String },
  data: () => ({
    svgPath: mdiCloseBoxOutline,
    dialog: false,
    valid: true,
    snackbar: false,
    first: "",
    last: "",
    phone: "",
    address: "",
    details: "",
    timestamp: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    isMobile: false,
  }),
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
  async  validate() {
      if (this.$refs.form.validate()) {
        this.getNow();
        this.snackbar = true;
        this.valid = false;
        const user = {
          first: this.first,
          last: this.last,
          phone: this.phone,
          address: this.address,
          details: this.details,
          email: this.email,
          time: this.timestamp,
        };
        const docRef = await addDoc(collection(db, "user"), user);
        console.log("Document written with ID: ", docRef.id);

        this.first = "";
        this.last = "";
        this.phone = "";
        this.address = "";
        this.details = "";
        this.email = "";
        this.timestamp = "";

this.$refs.form.reset();

        
      }
    },
  },
};

  

</script>
