<template>
  <div class="home">
    <section>
      <v-container id="lander" class="pa-0 ma-0 text-center" fluid>
        <v-row no-gutters class="text-center">
          <v-img
            :height="$vuetify.breakpoint.smAndDown ? '700px' : '930px'"
            class="pt-12"
            src="@/assets/Hero.webp"
          >
            <v-col class="pa-0 pt-8 mt-12">
              <h1
                :class="{
                  'display-3 py-6 font-weight-bold  animate__animated animate__bounce animate__delay-2s donkey': $vuetify.breakpoint.smAndDown,
                  'display-4 py-6 font-weight-bold  animate__animated animate__bounce animate__delay-2s donkey':
                    $vuetify.breakpoint.mdAndUp,
                }"
              >
                Window Cleaning <br />
                Experts
              </h1>
              <div class="pt-12 mt-6">
                <dialog1 title="CONTACT US" /></div
            ></v-col>
          </v-img>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-img
        src="@/assets/back.svg"
        :height="$vuetify.breakpoint.smAndDown ? '2250px' : '1550px'"
        position="bottom"
        alt=""
      >
        <!-- Mid headers -->
        <v-container fluid class="text-center px-0 pt-12">
          <div>
            <v-card flat class="transparent">
              <h1
                :class="{
                  'display-2 font-weight-black white--text my-12 py-12':
                    $vuetify.breakpoint.smAndDown,
                  'display-4 font-weight-black white--text py-12 my-12':
                    $vuetify.breakpoint.mdAndUp,
                }"
              >
                Wollongongs Local Team
              </h1>
              <h3 class="mb-12 pb-6 subheading white--text">
                High Quality Professionals<br />
                providing skilled service.
              </h3>
            </v-card>
          </div>
        </v-container>

        <v-container fluid>
          <!-- Window Card -->
          <v-row class="justify-center">
            <v-col cols="12" sm="8" md="4">
              <v-card class="white justify-center">
                <v-card-text class="text-center">
                  <v-icon x-large color="#00aeef">{{ svgPath[0] }}</v-icon>
                </v-card-text>
                <v-card-title class="justify-center">
                  <div class="headline text-center font-weight-bold pb-3">
                    <h3>Window Cleaning</h3>
                  </div>
                </v-card-title>
                <v-card-text class="text-center pb-12">
                  • Interior & Exterior Clean <br />
                  • Streak free shine <br />
                  • Frame cleaning <br />
                  • Track cleaning
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="light-blue" text @click="show0 = !show0"
                    >more
                    <v-icon color="light-blue">{{
                      show0 ? svgPath[4] : svgPath[5]
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="show0">
                    <v-card-text class="text-center">
                      The <span class="font-weight-bold">exterior</span> of your
                      property is the first<br />
                      thing that family and friends will see<br />
                      when entering your home or business.<br />
                      <br />
                      A <span class="font-weight-bold">clean</span> set of
                      windows will provide a<br />
                      great
                      <span class="font-weight-bold">first impression</span>
                      to guests or clients. <br />
                      <br />
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>

            <!-- Window Card -->

            <v-col cols="12" sm="8" md="4">
              <v-card class="white">
                <v-card-text class="text-center">
                  <v-icon x-large color="#00aeef">{{ svgPath[2] }}</v-icon>
                </v-card-text>
                <v-card-title class="justify-center">
                  <div class="headline text-center font-weight-bold pb-3">
                    <h3>House Washing</h3>
                  </div>
                </v-card-title>
                <v-card-text class="text-center pb-12">
                  • Refresh And Shine Your Home<br />
                  • Clean Pathways/Driveways <br />
                  • High/Low Pressure Wash <br />
                  • Impress Your Neighbours
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="light-blue" text @click="show2 = !show2"
                    >more
                    <v-icon color="light-blue">{{
                      show2 ? svgPath[4] : svgPath[5]
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="show2">
                    <v-card-text class="text-center">
                      <span class="font-weight-bold">High-pressure</span>
                      water blasting option<br />
                      is often required before painting or when<br />
                      dirt is ingrained in the property exterior.
                      <br />
                      <br />
                      <span class="font-weight-bold">Low-pressure</span>
                      wash is useful for ensuring <br />
                      no water entry into the building.<br />
                      A great alternative for treating <br />
                      more delicate areas.
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <!-- Commercial Card -->
            <v-col cols="12" sm="8" md="4">
              <v-card class="white">
                <v-card-text class="text-center">
                  <v-icon x-large color="#00aeef">{{ svgPath[3] }}</v-icon>
                </v-card-text>
                <v-card-title class="justify-center">
                  <div class="headline text-center font-weight-bold pb-3">
                    <h3>Commercial</h3>
                  </div>
                </v-card-title>
                <v-card-text class="text-center pb-12">
                  • Outshine Competition <br />
                  • Long Lasting<br />
                  • Store Fronts <br />
                  • Maintenance
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="light-blue" text @click="show3 = !show3"
                    >more
                    <v-icon color="light-blue">{{
                      show3 ? svgPath[4] : svgPath[5]
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <v-card v-show="show3" class="mx-auto">
                    <v-card-text class="text-center">
                      Excellent
                      <span class="font-weight-bold">Store front cleaning</span>
                      results
                      <br />
                      start with the right use of effective<br />
                      cleaning products. We take the time<br />
                      and worry out of having to <br />
                      manage these finer details.
                      <br />
                      <br />
                      To create a
                      <span class="font-weight-bold">long lasting</span>
                      clean,
                      <br />
                      you must inject an optimal volume <br />
                      of cleaning solution without<br />
                      too much water to allow the<br />
                      solution to act as a rinsing agent. <br />
                      <br />
                    </v-card-text>
                  </v-card>
                </v-expand-transition>
              </v-card>
            </v-col>

            <!-- Gutter Card -->

            <v-col cols="12" sm="8" md="4">
              <v-card class="white">
                <v-card-text class="text-center">
                  <v-icon x-large color="#00aeef">{{ svgPath[1] }}</v-icon>
                </v-card-text>
                <v-card-title class="justify-center">
                  <div class="headline font-weight-bold pb-3">
                    <h3>Gutter Cleaning</h3>
                  </div>
                </v-card-title>
                <v-card-text class="text-center pb-12">
                  • Protect Housing From Leaks <br />
                  • Prevent Rotting Roofs <br />
                  • Unblock Gutters <br />
                  • Save Time
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="light-blue" text @click="show1 = !show1"
                    >more
                    <v-icon color="light-blue">{{
                      show1 ? svgPath[4] : svgPath[5]
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="show1">
                    <v-card-text class="text-center">
                      If <span class="font-weight-bold">gutters</span> are not
                      cleaned and water
                      <br />
                      overflows from the gutters, it will fall <br />
                      along the foundation of the building. <br />
                      <br />
                      If the foundation is wet and then cools, <br />
                      this can cause heaving and cracks.
                      <br />
                      <br />
                      In extreme cases,
                      <span class="font-weight-bold">water damage</span>
                      <br />
                      can cause structural failure in a building.
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col></v-row
          >
        </v-container>
        <section>
          <v-container fluid>
            <!-- Aestetic Icons -->
            <v-card flat class="text-center mt-12 pt-12 mb-n12 transparent">
              <v-icon x-large class="white--text">{{ svgPath[0] }}</v-icon>
              <v-icon x-large class="white--text">{{ svgPath[1] }}</v-icon>
              <v-icon x-large class="white--text">{{ svgPath[2] }}</v-icon>
              <v-icon x-large class="white--text">{{ svgPath[3] }}</v-icon>
            </v-card>
          </v-container>
        </section>
      </v-img>
    </section>

    <section>
      <v-container fluid>
        <v-row justify="space-around">
          <v-col cols="12" md="6">
            <v-card flat class="transparent py-6">
              <v-card-title class="justify-center">
                <div class="headline font-weight-medium text-center">
                  <h3 class="text-no-wrap">
                    Overcome complex<br />
                    cleaning tasks
                  </h3>
                </div>
              </v-card-title>
              <v-card-text class="text-center">
                Rest while knowing you <br />
                Have the right people,<br />
                Equipment and expertise <br />
                Taking care of your home.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat class="text-center transparent py-6">
              <v-card-title class="justify-center">
                <div class="headline font-weight-medium text-center">
                  <h3>Eco-Friendly</h3>
                </div>
              </v-card-title>
              <v-card-text class="subtitle-2 pb-12 text-no-wrap">
                Our experts have an abundance<br />
                of experience shared.
                <span class="font-weight-bold">Exterior wash</span> uses<br />
                biodegradable & enviromentally friendly<br />
                chemicals to ensure we are<br />
                taking care of the planet.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <div class="py-6">
            <v-icon x-large color="#00aeef">{{ svgPath[0] }}</v-icon>
            <v-icon x-large color="#00aeef">{{ svgPath[1] }}</v-icon>
            <v-icon x-large color="#00aeef">{{ svgPath[2] }}</v-icon>
            <v-icon x-large color="#00aeef">{{ svgPath[3] }}</v-icon>
          </div>
        </v-row>
      </v-container>
    </section>

    <section>
      <!-- Sets up page with grid -->
      <v-container fluid>
        <!-- Horizontal Alignment -->
        <v-row>
          <v-col cols="12">
            <v-card flat class="transparent">
              <div class="py-6">
                <v-card-title class="justify-center">
                  <div class="headline font-weight-medium">
                    <h3>Contact us</h3>
                  </div>
                </v-card-title>
                <v-card-text class="text-center">
                  We will be in contact with you<br />
                  within<span class="font-weight-black"> 24 hours,</span> any
                  day of the week.
                  <div class="pa-5">
                    <dialog1 title="Contact Us" />
                  </div>
                </v-card-text>
                    
              </div>
              <v-row justify="center">
                <v-list class="transparent justify-center">
                  <v-list-item>
                    <v-list-item-action>
                      <a href="tel:+61481597034">
                        <v-icon medium color="#00aeef">{{ svgPath[7] }}</v-icon>
                      </a>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a href="tel:+61481597034"
                          ><h2>04-8159-7034</h2></a
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <a href="mailto:info@exteriorwash.com.au"
                        ><v-icon color="#00aeef">{{ svgPath[8] }}</v-icon></a
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        ><a href="mailto:info@exteriorwash.com.au"
                          ><h2>info@Exteriorwash.com.au</h2></a
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon color="#00aeef">{{ svgPath[6] }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Johnsonville<br />Wellington
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import dialog1 from "@/components/dialog1.vue";
import "animate.css"
import {
  mdiWindowClosedVariant,
  mdiHome,
  mdiWater,
  mdiRobotVacuumVariant,
  mdiChevronDown,
  mdiChevronUp,
  mdiMapMarker,
  mdiPhone,
  mdiEmail,
} from "@mdi/js";
export default {
  name: "home",
  components: {
    dialog1,
  },
  data: () => ({
    show0: false,
    show1: false,
    show2: false,
    show3: false,
    svgPath: [
      mdiWindowClosedVariant,
      mdiHome,
      mdiWater,
      mdiRobotVacuumVariant,
      mdiChevronUp,
      mdiChevronDown,
      mdiMapMarker,
      mdiPhone,
      mdiEmail,
    ],
  }),
  metaInfo: {
    title: "Exterior Wash - Window Cleaning Experts",
    meta: [
      {
        name: "description",
        content:
        "Wollongongs Window & House Washing Experts. Residential and Commercical Cleaning Available. Providing High Quality Services to wellington city. Leave the dirty work to us."
        ,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
#lander {
  background-color: #00aeef;
}

h1 {
  color: white;
}

.donkey {
  background-color: #0000008d;
}
</style>
