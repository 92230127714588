import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged} from "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyB929CzoFBbmlhTv6bkHP6V5YIaJ1vaqWA",
  authDomain: "connor-67aa5.firebaseapp.com",
  projectId: "connor-67aa5",
  storageBucket: "connor-67aa5.appspot.com",
  messagingSenderId: "127114757460",
  appId: "1:127114757460:web:068ef67eed7da3868f74c1",
  measurementId: "G-1F9BLE6K40"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth();

export { db, doc, setDoc, auth, onAuthStateChanged, signInWithEmailAndPassword };
